import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import './App.scss';
import './i18n';
import { useFetchTracking } from './api';
import { NotFound } from './pages/NotFound';
import { COUNTRY_LANGUAGE_MAP, DEFAULT_LANG, VALID_LANGUAGES, useUpdateLanguage } from './i18n';
import { useSocket } from './hooks/useSocket';
import { Routes, Route, useParams, Navigate, useSearchParams } from 'react-router-dom';
import { createLazyComponent } from './utils/lazyWithRetry';
import { timeBeforeDelivery } from './utils/helpers';
import { useCustomTheme } from './hooks/useCustomTheme';
import LoadingSpinnerCentered from './components/LoadingSpinnerCentered/LoadingSpinnerCentered';
import { useTranslation } from 'react-i18next';
const PreviewPage = createLazyComponent(() => import(/* webpackChunkName: "Preview" */ './pages/Preview'));
const OrderPage = createLazyComponent(() => import(/* webpackChunkName: "OrderPage" */ './pages/Order/Order'));
const DeliveryPreferences = createLazyComponent(() => import(/* webpackChunkName: "DeliveryPreferences" */ './pages/DeliveryPreferences'));
const LiveTrackingPage = createLazyComponent(() => import(/* webpackChunkName: "LiveTrackingPage" */ './pages/LiveTracking'));
const Router = () => {
    var _a, _b;
    const { trackingId, lang } = useParams();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const skipPreferences = useMemo(() => searchParams.has('__skip-pref__'), []);
    const { isLoading, data: order, error, setData: updateOrder } = useFetchTracking(trackingId);
    useSocket(trackingId, (_a = order === null || order === void 0 ? void 0 : order.statusCode) !== null && _a !== void 0 ? _a : 0, updateOrder);
    useUpdateLanguage(lang);
    const customTheme = useCustomTheme(order === null || order === void 0 ? void 0 : order.account);
    // This is calculated once per order update to not count down if there is no location updates
    const estimatedTimeBeforeDelivery = useMemo(() => (order ? timeBeforeDelivery(t, order.promisedTime, order.timeDiff) : ''), [order, lang]);
    if (isLoading)
        return _jsx(LoadingSpinnerCentered, {});
    if (!order || error)
        return _jsx(NotFound, { error: error === null || error === void 0 ? void 0 : error.message });
    if (!lang || !VALID_LANGUAGES.includes(lang)) {
        const newLang = (_b = COUNTRY_LANGUAGE_MAP[order.deliveryAddress.country_code.toUpperCase()]) !== null && _b !== void 0 ? _b : DEFAULT_LANG;
        return _jsx(Navigate, { to: `/${trackingId}/${newLang}`, replace: true });
    }
    const needsToConfirmPreferences = !skipPreferences && !order.hasConfirmedPreferences;
    if (needsToConfirmPreferences && !window.location.pathname.endsWith('preferences')) {
        return _jsx(Navigate, { to: `/${trackingId}/${lang}/preferences`, replace: true });
    }
    return (_jsx("div", { className: "App", style: customTheme, children: _jsxs(Routes, { children: [_jsx(Route, { path: `preferences`, element: _jsx(DeliveryPreferences, { clientName: order.account.name, deliveryAddress: order.deliveryAddress, preferences: order.deliveryPreferences, isTech: order.account.isTech, trackingId: trackingId, updateOrder: updateOrder, customerImageUrl: order.account.logo, hasConfirmedPreferences: !needsToConfirmPreferences, deliveryRequirements: order.deliveryRequirements, serviceType: order.serviceType, alwaysDeliverOutside: order.account.alwaysDeliverOutside }) }), _jsx(Route, { path: `live-tracking`, element: _jsx(LiveTrackingPage, { order: order, estimatedTimeBeforeDelivery: estimatedTimeBeforeDelivery }) }), _jsx(Route, { path: '/', element: _jsx(OrderPage, { estimatedTimeBeforeDelivery: estimatedTimeBeforeDelivery, trackingId: trackingId, updateOrder: updateOrder, order: order }) }), _jsx(Route, { path: "*", element: _jsx(NotFound, {}) })] }) }));
};
export const App = () => {
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/___preview___/*", element: _jsx(PreviewPage, {}) }), _jsx(Route, { path: "/:trackingId", element: _jsx(Router, {}) }), _jsx(Route, { path: "/:trackingId/:lang/*", element: _jsx(Router, {}) }), _jsx(Route, { path: "*", element: _jsx(NotFound, {}) })] }));
};
