import React from 'react';
export const TableListContext = React.createContext(false);
export const TableContext = React.createContext({
    sortedColumn: undefined,
    expandable: false,
    sortableColumns: [],
    sort: () => { },
    sortDirection: 'asc',
    condensed: false,
});
