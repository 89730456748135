import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './Alert.module.scss';
import { IconAlertCircle, IconAlertTriangle, IconCheckCircle, IconXClose } from '../Icon';
import { classNames } from '../utils/helperFunctions';
import { Heading, Paragraph } from '../Typography';
function Icon({ variant }) {
    switch (variant) {
        case 'warning':
            return _jsx(IconAlertTriangle, { color: "warning" });
        case 'success':
            return _jsx(IconCheckCircle, { color: "success" });
        case 'primary':
            return _jsx(IconAlertCircle, { color: "brand-strong" });
        case 'error':
            return _jsx(IconAlertCircle, { color: "error" });
        default:
            return _jsx(IconAlertCircle, {});
    }
}
export function Alert({ variant = 'primary', heading, supportingText, onClose, autoFocusOnClose = false, children, }) {
    return (_jsx("div", { role: "alert", className: classNames(styles.base, styles[`variant--${variant}`]), children: _jsxs("div", { className: styles['alert-content-wrapper'], children: [_jsx(Icon, { variant: variant }), _jsxs("div", { className: styles.content, children: [_jsxs("div", { children: [_jsx(Heading, { size: "h6", children: heading }), supportingText && _jsx(Paragraph, { children: supportingText })] }), children] }), onClose && (_jsx("button", { autoFocus: autoFocusOnClose, type: "button", className: styles['close-button'], onClick: onClose, children: _jsx(IconXClose, { color: "strong" }) }))] }) }));
}
