import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Paragraph } from '../Typography';
import styles from './Checkbox.module.scss';
import { classNames, stopPropagation } from '../utils/helperFunctions';
import { IconCheck, IconMinus } from '../Icon';
export function Checkbox({ checked, label, supportingText, isIntermediate = false, disabled = false, onChange, title = label, type = 'default', }) {
    const Icon = isIntermediate && !checked ? IconMinus : IconCheck;
    return (_jsxs("label", { className: classNames(styles.container, (checked || isIntermediate) && styles.checked, disabled && styles.disabled), children: [_jsx("span", { className: styles.box, children: _jsx(Icon, { size: "small", color: disabled ? 'disabled' : 'inverse' }) }), _jsx("input", { "aria-label": label !== null && label !== void 0 ? label : title, onClick: onChange && !disabled ? stopPropagation : undefined, type: "checkbox", checked: checked, disabled: disabled, onChange: onChange }), type === 'default' && label ? (_jsxs("div", { className: styles.content, children: [_jsx(Paragraph, { strong: true, color: disabled ? 'disabled' : undefined, children: label }), supportingText && (_jsx(Paragraph, { color: disabled ? 'disabled' : 'subtle', children: supportingText }))] })) : (type === 'inline' &&
                label && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.contentTypeInline, children: _jsx("div", { className: styles.labelTypeInline, children: _jsx(Paragraph, { strong: true, color: disabled ? 'disabled' : undefined, children: label }) }) }), _jsx("div", { className: styles.supportingTextTypeInline, children: supportingText && (_jsx(Paragraph, { color: disabled ? 'disabled' : 'subtle', children: supportingText })) })] })))] }));
}
