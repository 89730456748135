import * as i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import dayjs from 'dayjs';
import 'dayjs/locale/sv.js';
import 'dayjs/locale/da.js';
import 'dayjs/locale/nb.js';
import 'dayjs/locale/fi.js';
import 'dayjs/locale/is.js';
import 'dayjs/locale/lv.js';
import 'dayjs/locale/es.js';
import 'dayjs/locale/et.js';
import { useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
    backend: {
        loadPath: '/locale/{lng}.json',
    },
    lng: 'en_GB',
    load: 'currentOnly',
    fallbackLng: false,
    returnEmptyString: false,
    interpolation: {
        prefix: '{',
        suffix: '}',
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
});
export const DEFAULT_LANG = 'en_GB';
export const COUNTRY_LANGUAGE_MAP = {
    EN: 'en_GB',
    DK: 'da_DK',
    FI: 'fi_FI',
    NO: 'no_NO',
    SE: 'sv_SE',
    IS: 'is_IS',
    LV: 'lv_LV',
    ES: 'es_ES',
    EE: 'et_EE',
};
export const VALID_LANGUAGES = Object.values(COUNTRY_LANGUAGE_MAP);
export function buildSupportUrl(country) {
    const host = 'https://www.gordondelivery.com';
    switch (country.toUpperCase()) {
        case 'DK':
            return `${host}/da/support`;
        case 'SE':
            return `${host}/sv/support`;
        // show the English version since the official website redirect to the Swedish page if provided lang is not found
        default:
            return `${host}/en/support`;
    }
}
export const useUpdateLanguage = (lang) => {
    const { i18n } = useTranslation();
    useEffect(() => {
        if (lang && VALID_LANGUAGES.includes(lang)) {
            const dayjsLang = lang.split('_')[0].toLowerCase();
            dayjs.locale(dayjsLang);
            i18n.changeLanguage(lang, () => console.log('Language changed'));
        }
    }, [lang]);
};
export const useLanguage = () => {
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const { pathname } = useResolvedPath('');
    return [
        i18n.language,
        (lang) => {
            const newPath = pathname.replace(i18n.language, lang);
            navigate(newPath, { replace: true });
        },
    ];
};
export default i18n;
