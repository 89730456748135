import dayjs from 'dayjs';
export const getDaysInMonth = (date) => {
    const numberOfDaysInMonth = dayjs(date).daysInMonth();
    return Array.from({ length: numberOfDaysInMonth }, (_, i) => i + 1);
};
export const getPrevDaysInFirstWeekOfTheMonth = (date) => {
    const dayInWeek = dayjs(date).set('date', 1).day() || 7;
    const prevMonthDays = dayjs(date).subtract(1, 'month').daysInMonth();
    return Array.from({ length: dayInWeek - 1 }, (_, i) => prevMonthDays - i).reverse();
};
export const getNextDaysInLastWeekOfTheMonth = (date) => {
    const dayInWeek = dayjs(date).set('date', dayjs(date).daysInMonth()).day();
    return Array.from({ length: dayInWeek && 7 - dayInWeek }, (_, i) => i + 1);
};
export const nextMonth = (date) => {
    return dayjs(date).add(1, 'month').toDate();
};
export const prevMonth = (date) => {
    return dayjs(date).subtract(1, 'month').toDate();
};
export const isPartOfRange = (date, start, end) => {
    return dayjs(date).isAfter(start) && dayjs(date).isBefore(end);
};
export const isSameDay = (date1, date2) => {
    return dayjs(date1).isSame(date2, 'day');
};
export const isSameMonth = (date1, date2) => {
    return dayjs(date1).isSame(date2, 'month');
};
export const printYearAndDate = (date) => {
    return dayjs(date).format('MMMM YYYY');
};
export const isValidDate = (date) => {
    return date.length === 10 && !date.includes('-') && !isNaN(new Date(date).getMilliseconds());
};
