import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { Alert } from '../Alert';
import { createPortal } from 'react-dom';
import styles from './ToastsProvider.module.scss';
import { classNames } from '../utils/helperFunctions';
const ToastsContext = React.createContext(() => { });
export const ToastsProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);
    const showToast = useCallback((toast, autoHide = 0) => {
        const id = Math.random().toString(36).substring(2, 9);
        const closeToast = () => setToasts((prevToasts) => prevToasts.filter((t) => t.id !== id));
        if (autoHide) {
            setTimeout(() => {
                setToasts((prevToasts) => prevToasts.map((t) => (t.id === id ? Object.assign(Object.assign({}, t), { toDelete: true }) : t)));
                setTimeout(closeToast, 600);
            }, Math.max(autoHide, 1000));
        }
        setToasts((prevToasts) => [
            ...prevToasts,
            {
                props: Object.assign(Object.assign({}, toast), { onClose: closeToast }),
                id,
            },
        ]);
    }, [setToasts]);
    return (_jsxs(ToastsContext.Provider, { value: showToast, children: [children, createPortal(_jsx("div", { className: styles.container, children: toasts.map((toast) => (_jsx("div", { className: classNames(styles.toast, toast.toDelete && styles['about-to-remove']), children: _jsx(Alert, Object.assign({}, toast.props), toast.id) }))) }), document.body)] }));
};
export const useToast = () => React.useContext(ToastsContext);
