import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './SelectButton.module.scss';
import { IconChevronDown, IconChevronUp, IconXCircle } from '../Icon';
import { Paragraph } from '../Typography';
import { classNames } from '../utils/helperFunctions';
import { LoadingSpinner } from '../LoadingSpinner';
export const SelectButton = React.forwardRef(({ id, isLoading, setExpanded, disabled, isExpanded, children, icon: Icon, placeholder, variant = 'primary', error = false, calculatedWidth = '100%', preview, selectWidth, clearable, clearSelected, }, ref) => {
    return (_jsxs("button", { id: id, onClick: () => {
            setExpanded(!isExpanded);
        }, disabled: disabled, className: classNames(styles.base, styles[`variant--${variant}`], error && styles.error, preview === 'count' && styles.removeGap), ref: ref, role: "combobox", "aria-haspopup": "listbox", "aria-expanded": isExpanded, "aria-controls": `${id}-listbox`, "aria-label": placeholder, value: undefined, type: "button", style: {
            width: variant != 'tertiary' ? (!selectWidth ? calculatedWidth : selectWidth) : undefined,
        }, children: [Icon && _jsx(Icon, { color: disabled ? 'disabled' : !children ? 'subtle' : 'strong' }), _jsx("span", { className: styles.content, children: children || (_jsx(Paragraph, { color: disabled ? 'disabled' : variant === 'tertiary' ? 'strong' : 'subtle', "aria-hidden": true, children: placeholder })) }), clearable && (_jsx("button", { type: "button", onMouseDown: (e) => {
                    e.preventDefault();
                    clearSelected && clearSelected();
                }, title: "Clear selected", name: "clear", tabIndex: -1, children: _jsx(IconXCircle, { color: disabled ? 'disabled' : 'strong' }) })), isLoading && _jsx(LoadingSpinner, { size: "standard" }), !isLoading && isExpanded && _jsx(IconChevronUp, { color: disabled ? 'disabled' : 'strong' }), !isLoading && !isExpanded && _jsx(IconChevronDown, { color: disabled ? 'disabled' : 'strong' })] }));
});
export const TagList = ({ children }) => {
    return _jsx("div", { className: styles['tag-list'], children: children });
};
