import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DivIcon, Point } from 'leaflet';
import { useEffect, useMemo, useRef, useState, } from 'react';
import { renderToString } from 'react-dom/server';
import { CircleMarker, Marker } from 'react-leaflet';
import styles from './MapPin.module.scss';
import { classNames } from '../utils/helperFunctions';
import { tokenColors } from '../styles';
import { createPortal } from 'react-dom';
function hexToRgb(hex) {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
        r = parseInt(hex.slice(1, 3), 16);
        g = parseInt(hex.slice(3, 5), 16);
        b = parseInt(hex.slice(5, 7), 16);
    }
    return { r, g, b };
}
function rgbToHex(r, g, b) {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
}
function luminance(r, g, b) {
    const a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}
function contrast(rgb1, rgb2) {
    const lum1 = luminance(rgb1.r, rgb1.g, rgb1.b);
    const lum2 = luminance(rgb2.r, rgb2.g, rgb2.b);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
}
function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}
function formatHex(hex) {
    return hex.slice(hex.indexOf('#'), hex.indexOf('#') + 7);
}
function getTextColor(hex) {
    const rgb = hexToRgb(formatHex(hex));
    const black = { r: 0, g: 0, b: 0 };
    const white = { r: 255, g: 255, b: 255 };
    const blackContrast = contrast(rgb, black);
    const whiteContrast = contrast(rgb, white);
    return blackContrast > whiteContrast ? 'strong' : 'inverse';
}
function darkenHexColor(hex, opacity) {
    // Convert hex to RGB
    const { r, g, b } = hexToRgb(formatHex(hex));
    // Apply black overlay with the given opacity
    const newR = Math.round(r * (1 - opacity));
    const newG = Math.round(g * (1 - opacity));
    const newB = Math.round(b * (1 - opacity));
    // Convert the resulting RGB back to hex
    return rgbToHex(clamp(newR, 0, 255), clamp(newG, 0, 255), clamp(newB, 0, 255));
}
const typeToColor = {
    black: formatHex(tokenColors.iconsColors.strong),
    custom: formatHex(tokenColors.iconsColors.strong), //fallback color
    brand: formatHex(tokenColors.backgroundsColors.interactive.brand.default),
    warning: formatHex(tokenColors.backgroundsColors.interactive.warning.default),
    destructive: formatHex(tokenColors.backgroundsColors.interactive.error.default),
};
const typeToActiveColor = {
    black: formatHex(tokenColors.backgroundsColors.interactive.inverse.active),
    custom: formatHex(tokenColors.iconsColors.strong), //fallback color
    brand: formatHex(tokenColors.backgroundsColors.interactive.brand.active),
    warning: formatHex(tokenColors.backgroundsColors.interactive.warning.active),
    destructive: formatHex(tokenColors.backgroundsColors.interactive.error.active),
};
const isCircleMarker = (marker, isPerformanceMarker) => isPerformanceMarker;
export const MapPin = ({ isPerformanceMarker = false, coordinates, content, type = 'brand', alt, color, textColor, isActive, isDeselected, icon: Icon, children, onClick, id, fullColor, }) => {
    const [popupNode, setPopupNode] = useState(undefined);
    const isShowingPopup = !!popupNode;
    const size = Icon || (content && String(content).length > 4) ? 'large' : 'medium';
    const width = size === 'large' ? 40 : 32;
    const height = size === 'large' ? 50 : 40;
    const markerRef = useRef(null);
    useEffect(() => {
        if (markerRef.current && id) {
            markerRef.current.options.customData = { id: id };
        }
    }, [id, isPerformanceMarker]); // needs to re-set the id if the marker switches between performance and regular marker
    const calculatedTextColor = useMemo(() => textColor !== null && textColor !== void 0 ? textColor : (color && color.indexOf('#') >= 0 ? getTextColor(color) : 'inverse'), [color]);
    const icon = useMemo(() => Icon && renderToString(_jsx(Icon, { color: textColor, size: "large" })), [Icon, textColor]);
    const fillColor = color && type === 'custom' ? color : typeToColor[type];
    const hoverColor = useMemo(() => darkenHexColor(fillColor, 0.1), [fillColor]);
    const activeColor = useMemo(() => (type === 'custom' ? darkenHexColor(fillColor, 0.2) : typeToActiveColor[type]), [fillColor]);
    useEffect(() => {
        var _a;
        if (isCircleMarker(markerRef, isPerformanceMarker) && ((_a = markerRef.current) === null || _a === void 0 ? void 0 : _a.setStyle)) {
            markerRef.current.setStyle({
                fillColor: isActive || isShowingPopup ? activeColor : fillColor,
                fillOpacity: isDeselected ? 0.5 : 1,
            });
        }
    }, [type, fillColor, activeColor, isActive, isDeselected, isShowingPopup]);
    const customStyles = type === 'custom' && color
        ? `--custom-map-pin-color:${color}; --custom-map-pin-text-color:${fullColor ? 'transparent' : tokenColors.textColors[calculatedTextColor]}; --custom-map-pin-color-hover:${hoverColor}; --custom-map-pin-color-active:${activeColor}; ${isDeselected ? 'opacity: 0.5;' : ''}`
        : `${isDeselected ? 'opacity: 0.5;' : ''}`;
    const divIcon = useMemo(() => new DivIcon({
        iconAnchor: new Point(width / 2, height),
        iconSize: new Point(width, height),
        className: classNames(styles.MapPin, styles[`Size_${size}`], styles[`MapPin_${type}`], (isActive || isShowingPopup) && styles['_active'], isDeselected && styles['_deselected']),
        html: `
                    <div class=${styles['icon-top']} style="${customStyles}">
                        ${icon !== null && icon !== void 0 ? icon : (content ? `<p>${content}</p>` : '')} 
                    </div>
                    <div class=${styles['icon-middle']} style="${customStyles}"></div>
                    <div class=${styles['icon-bottom']} style="${customStyles}"></div>
                `,
    }), [
        width,
        height,
        color,
        type,
        content,
        isActive,
        isShowingPopup,
        isDeselected,
        customStyles,
        icon,
    ]);
    const eventHandlers = useMemo(() => onClick || children
        ? {
            click: (event) => {
                if (children && popupNode) {
                    event.target.unbindPopup();
                    setPopupNode(undefined);
                }
                else if (children) {
                    event.target
                        .bindPopup('', {
                        offset: isPerformanceMarker ? [0, 4] : [0, -height + 8],
                        closeOnEscapeKey: true,
                        className: classNames(styles['MapPopup']),
                    })
                        .openPopup();
                    setPopupNode(event.target.getPopup()._contentNode);
                    event.target.once('popupclose', () => {
                        event.target.unbindPopup();
                        setPopupNode(undefined);
                    });
                }
                onClick === null || onClick === void 0 ? void 0 : onClick();
            },
        }
        : undefined, [onClick, popupNode, isPerformanceMarker]);
    const popup = popupNode &&
        children &&
        createPortal(_jsxs("div", { className: classNames(styles['MapPopup_content'], styles[`MapPopup_${type}`]), style: type === 'custom' ? { backgroundColor: activeColor } : {}, children: [children, _jsx("div", { className: classNames(styles['MapPopup_content_tip'], styles[`MapPopup_${type}`]), style: type === 'custom' ? { backgroundColor: activeColor } : {} })] }), popupNode);
    return (_jsxs(_Fragment, { children: [isCircleMarker(markerRef, isPerformanceMarker) ? (_jsx(CircleMarker, { ref: markerRef, center: coordinates, radius: 4, color: "transparent", fillColor: isActive || isShowingPopup ? activeColor : fillColor, stroke: false, fillOpacity: isDeselected ? 0.5 : 1, eventHandlers: eventHandlers })) : (_jsx(Marker, { ref: markerRef, position: coordinates, icon: divIcon, alt: alt, eventHandlers: eventHandlers })), popup] }));
};
