import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_WIDTH, TABLET_WIDTH } from './helperFunctions';
import { ModalTransition } from '../Modal';
export function useKeyboardShortcut(pressedKey, callback) {
    useEffect(() => {
        function onKeyDown(e) {
            var _a, _b;
            const key = e.key;
            if (!key || key !== pressedKey)
                return;
            const isInputActive = ((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.tagName) === 'INPUT' || ((_b = document.activeElement) === null || _b === void 0 ? void 0 : _b.tagName) === 'SELECT';
            if (isInputActive && key !== 'Escape' && key !== 'Enter')
                return;
            if (!callback || typeof callback !== 'function')
                throw new Error('The second parameter needs to be a function');
            callback();
        }
        document.addEventListener('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
        };
    }, [pressedKey, callback]);
}
export const useDisableBodyScroll = () => {
    const [scrollDisabled, setScrollDisabled] = useState(false);
    useEffect(() => {
        const { body } = document;
        if (!body)
            return;
        body.style.overflow = scrollDisabled ? 'hidden' : 'auto';
    }, [scrollDisabled]);
    return [scrollDisabled, setScrollDisabled];
};
export function useDetectClickOutside(active, callback, ...refs) {
    useEffect(() => {
        if (!active)
            return;
        function handleClickOutside(event) {
            if (refs.every((ref) => { var _a; return event.target instanceof HTMLElement && !((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)); })) {
                callback();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [active, callback, ...refs]);
}
export const useDeviceWidth = () => {
    const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH})` });
    const isTablet = useMediaQuery({
        query: `(min-width: ${MOBILE_WIDTH}) and (max-width : ${TABLET_WIDTH} )`,
    });
    const isDesktop = useMediaQuery({
        query: `(min-width: ${TABLET_WIDTH})`,
    });
    return { isMobile, isTablet, isDesktop };
};
const compareStrings = (a, b) => a.localeCompare(b, undefined, { sensitivity: 'base', numeric: true });
export const useTableSort = (data, sortMapper, defaultSortColumn, defaultSortDirection = 'asc') => {
    const [sortedColumn, setSortedColumn] = useState(defaultSortColumn);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);
    const sortableColumns = useMemo(() => Object.keys(sortMapper), []);
    const sort = useCallback((column) => {
        if (column === sortedColumn) {
            setSortDirection((direction) => (direction === 'asc' ? 'desc' : 'asc'));
        }
        else {
            setSortedColumn(column);
            setSortDirection('asc');
        }
    }, [setSortedColumn, setSortDirection, sortedColumn]);
    const sortedData = useMemo(() => sortedColumn === undefined
        ? data
        : data.sort((a, b) => {
            const aData = sortMapper[sortedColumn](a);
            const bData = sortMapper[sortedColumn](b);
            //Sorts undefined values to the end of the list
            if (aData === undefined || bData === undefined) {
                if (aData === bData) {
                    return 0;
                }
                return aData === undefined ? 1 : -1;
            }
            if (typeof aData === 'string' || typeof bData === 'string') {
                return sortDirection === 'asc'
                    ? compareStrings(String(aData), String(bData))
                    : compareStrings(String(bData), String(aData));
            }
            return sortDirection === 'asc'
                ? Number(aData) - Number(bData)
                : Number(bData) - Number(aData);
        }), [data, sortedColumn, sortDirection]);
    return { sortedData, sortableColumns, sortedColumn, sortDirection, sort };
};
export function useModals(modals) {
    const [activeModal, setActiveModal] = useState(null);
    const [props, setProps] = useState(null);
    const closeModal = () => {
        setActiveModal(null);
        setProps(null);
    };
    const setModal = (modal, props) => {
        setActiveModal(modal);
        setProps(props);
    };
    const Modal = useMemo(() => {
        const CurrentModal = activeModal
            ? modals[activeModal]
            : undefined;
        return (_jsx(ModalTransition, { children: CurrentModal &&
                createPortal(_jsx(CurrentModal, Object.assign({}, props, { onClose: closeModal })), document.body) }));
    }, [activeModal]);
    return [Modal, setModal, closeModal];
}
const toArray = (value) => Array.isArray(value) ? value : !!value ? [value] : [];
const findOrAdd = (array, check, value) => {
    const found = array.find(check);
    if (found) {
        return found;
    }
    array.push(value);
    return value;
};
export function useFilters(data, filterFunctions, filteredValues) {
    const keys = useMemo(() => Object.keys(filterFunctions), [filterFunctions]);
    const filters = useMemo(() => keys.reduce((f, key) => (Object.assign(Object.assign({}, f), { [key]: [] })), {}), keys);
    const filteredData = useMemo(() => {
        return data.filter((data) => {
            const optionsToUpdate = [];
            const keyResults = keys.map((key) => {
                const values = toArray(filteredValues[key]);
                const filter = filters[key];
                const filterFunction = filterFunctions[key];
                const results = toArray(filterFunction(data));
                const matches = results.map((result) => {
                    const option = findOrAdd(filter, ({ value }) => value === result.value, Object.assign(Object.assign({}, result), { visibleCount: 0, totalCount: 0 }));
                    option.totalCount += 1;
                    const matchesSelectedValue = values.includes(result.value);
                    if (matchesSelectedValue) {
                        optionsToUpdate.push(option);
                    }
                    return matchesSelectedValue;
                });
                return values.length === 0 || matches.some(Boolean);
            });
            const shouldStayInList = keyResults.every(Boolean);
            optionsToUpdate.forEach((option) => {
                option.visibleCount += shouldStayInList ? 1 : 0;
            });
            return shouldStayInList;
        });
    }, [data, filteredValues, filters, ...keys]);
    return [filteredData, filters];
}
