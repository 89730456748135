import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '../utils/helperFunctions';
import styles from './Button.module.scss';
import { forwardRef, useCallback } from 'react';
import { LoadingSpinner } from '../LoadingSpinner';
const variantToIconColor = {
    primary: 'inverse',
    secondary: 'default',
    tonal: 'default',
    tertiary: 'default',
    'tertiary-color': 'brand-primary',
    destructive: 'inverse',
    'tertiary-error': 'error',
};
export const Button = forwardRef(({ text, icon: Icon, iconType = 'leading', onClick, type = 'button', variant, size = 'medium', disabled = false, active = false, isLoading = false, ariaControls, ariaExpanded, ariaHaspopup, ariaLabel, tabIndex, }, ref) => {
    const clickHandler = useCallback((event) => {
        event.stopPropagation();
        if (!disabled && onClick) {
            return onClick();
        }
    }, [disabled, onClick]);
    const iconSize = size === 'large' ? 'large' : size === 'medium' ? 'large' : 'standard';
    const iconColor = disabled ? 'disabled' : variantToIconColor[variant];
    return (_jsxs("button", { ref: ref, type: type, title: text, onClick: clickHandler, disabled: disabled || isLoading, tabIndex: tabIndex, "aria-controls": ariaControls, "aria-expanded": ariaExpanded, "aria-haspopup": ariaHaspopup, "aria-label": ariaLabel, className: classNames(styles.base, styles[`variant-${variant}`], active && styles[`variant-${variant}--active`], styles[`size-${size}`], iconType === 'only' && styles[`size-${size}-icon-only`], iconType === 'trailing' && styles.iconTrailing), children: [Icon && _jsx(Icon, { color: iconColor, size: iconSize }), iconType !== 'only' && text, isLoading && (_jsx("div", { className: classNames(styles.loading, styles[`loading-${variant}`]), children: _jsx(LoadingSpinner, { color: iconColor, size: iconSize }) }))] }));
});
Button.displayName = 'Button';
