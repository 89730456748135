import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getDaysInMonth, getPrevDaysInFirstWeekOfTheMonth, getNextDaysInLastWeekOfTheMonth, nextMonth, prevMonth, printYearAndDate, isSameDay, isSameMonth, } from './utils';
import styles from './Calendar.module.scss';
import { Button } from '../Button';
import { IconChevronLeft, IconChevronRight } from '../Icon';
import { Heading, Paragraph } from '../Typography';
import { classNames } from '../utils/helperFunctions';
const CalendarItem = ({ isHeading, disabled, selected, highlighted, children, onClick, isToday, }) => {
    const Tag = isHeading ? 'div' : 'button';
    return (_jsx(Tag, { tabIndex: -1, onClick: onClick, className: classNames(styles.item, selected && styles.selected, isToday && styles.today, highlighted && styles.highlighted), children: _jsx(Paragraph, { strong: isHeading, size: isHeading ? 'large' : 'standard', color: disabled ? 'disabled' : isToday ? 'default' : selected ? 'inverse' : 'strong', children: children }) }));
};
const createDate = (date, day) => dayjs(date).set('date', day);
export const Calendar = React.forwardRef(({ date, onChange, highlightedDate, alignment }, ref) => {
    const [internalDate, setInternalDate] = useState(date !== null && date !== void 0 ? date : new Date());
    const daysInPrevMonth = getPrevDaysInFirstWeekOfTheMonth(internalDate);
    const daysInThisMonth = getDaysInMonth(internalDate);
    const daysInNextMonth = getNextDaysInLastWeekOfTheMonth(internalDate);
    useEffect(() => {
        if (date && !isSameMonth(date, internalDate)) {
            setInternalDate(date);
        }
    }, [date]);
    useEffect(() => {
        if (highlightedDate && !isSameMonth(highlightedDate, internalDate)) {
            setInternalDate(highlightedDate);
        }
    }, [highlightedDate]);
    const alignmentClass = alignment === 'left' ? styles['alignment--left'] : styles['alignment--right'];
    return (_jsxs("div", { className: classNames(styles.base, alignmentClass), ref: ref, onPointerDown: (e) => {
            e.preventDefault();
        }, children: [_jsxs("div", { className: styles.header, children: [_jsx(Button, { text: "Previous month", icon: IconChevronLeft, iconType: "only", variant: "tertiary", size: "medium", tabIndex: -1, onClick: () => setInternalDate(prevMonth(internalDate)) }), _jsx(Heading, { size: "h5", children: printYearAndDate(internalDate) }), _jsx(Button, { text: "Previous month", icon: IconChevronRight, iconType: "only", variant: "tertiary", size: "medium", tabIndex: -1, onClick: () => setInternalDate(nextMonth(internalDate)) })] }), _jsxs("div", { className: styles.body, children: [['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((day) => (_jsx(CalendarItem, { isHeading: true, children: day }, day))), daysInPrevMonth.map((day) => (_jsx(CalendarItem, { disabled: true, onClick: () => onChange(createDate(internalDate, day).subtract(1, 'month').toDate()), children: day }, day))), daysInThisMonth.map((day) => (_jsx(CalendarItem, { isToday: isSameDay(new Date(), createDate(internalDate, day)), highlighted: highlightedDate && isSameDay(highlightedDate, createDate(internalDate, day)), selected: date && isSameDay(date, createDate(internalDate, day)), onClick: () => onChange(createDate(internalDate, day).toDate()), children: day }, day))), daysInNextMonth.map((day) => (_jsx(CalendarItem, { disabled: true, onClick: () => onChange(createDate(internalDate, day).add(1, 'month').toDate()), children: day }, day)))] })] }));
});
