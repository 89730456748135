import { jsx as _jsx } from "react/jsx-runtime";
import { stateColorMap } from './shared';
import { classNames } from '../utils/helperFunctions';
import { IconCheck, IconExclamationMark, IconXClose } from '../Icon';
import styles from './TimelineStep.module.scss';
const stepIconMap = {
    default: _jsx("div", { className: styles['timeline-step__icon__default'] }),
    active: _jsx("div", { className: styles['timeline-step__icon__active'] }),
    done: _jsx(IconCheck, { size: "small", color: "inverse" }),
    warning: _jsx(IconExclamationMark, { size: "small", color: "inverse" }),
    error: _jsx(IconXClose, { size: "small", color: "inverse" }),
    dashed: _jsx("div", { className: styles['timeline-step__icon__default'] }),
};
export const TimelineCircle = ({ state }) => {
    return (_jsx("div", { className: classNames(styles['timeline-step__icon'], styles[stateColorMap[state]]), children: stepIconMap[state] }));
};
