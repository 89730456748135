var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Children, useCallback } from 'react';
import { useDeviceWidth, useKeyboardShortcut } from '../utils/hooks';
import { Button } from '../Button';
import './DeprecatedModal.scss';
import { Paginate } from './Paginate';
export function DeprecatedModal({ heading, headerWidth, // need to wrap longer heading or supporting text
supportingText, ctaText, cancelText = 'Cancel', itemsStacked = false, // items will be stacked in mobile view, such as in the footer
ctaDisabled = false, isLoading = false, destructive = false, ctaClick = () => null, onClose = () => { }, allowOverFlow = false, // content that overflow like datePicker calender or dropDown need to have "visible" overflow, this disabled scroll on overflow
paginate, // for more than one page modals
hasCta = true, // shows the bottom cta and cancel buttons
testId, children, }) {
    const handleEscapeKeypress = useCallback(() => {
        onClose();
    }, []);
    useKeyboardShortcut('Escape', handleEscapeKeypress);
    const { isMobile } = useDeviceWidth();
    return (_jsx(_Fragment, { children: _jsx("div", { "data-testid": testId, className: 'u-ui-deprecated-modal__wrapper', onScroll: (e) => e.stopPropagation(), children: _jsxs("div", { className: `u-ui-deprecated-modal__content-wrapper`, onClick: (e) => e.stopPropagation(), children: [_jsxs("div", { className: `${!paginate
                            ? 'u-ui-deprecated-modal__header'
                            : 'u-ui-deprecated-modal__headerWithPaginate'}`, style: { width: isMobile ? '100%' : headerWidth }, children: [!paginate ? (_jsx("div", { className: "u-ui-deprecated-modal__header-heading", children: heading })) : (_jsxs("div", { className: 'u-ui-deprecated-modal__headerWithPaginate-paginateWrapper', children: [_jsx("span", { className: "u-ui-deprecated-modal__header-heading", children: heading }), _jsx("span", { className: "u-ui-deprecated-modal__headerWithPaginate-paginateWrapper-paginate", children: paginate.map((page) => (_jsx(Paginate, { label: page.label, id: page.id, done: page.done }, page.id))) })] })), supportingText && (_jsx("div", { className: "u-ui-deprecated-modal__header-supporting-text", children: supportingText }))] }), children && (_jsx("div", { className: ` ${!allowOverFlow
                            ? 'u-ui-deprecated-modal__content'
                            : 'u-ui-deprecated-modal__overflowContent'} `, children: children })), _jsxs("div", { className: `u-ui-deprecated-modal__footer ${!hasCta ? 'no-cta' : itemsStacked ? 'items--stacked' : ''}`, children: [_jsx(Button, { variant: "secondary", text: cancelText, onClick: onClose }), !!hasCta && ctaText && (_jsx(Button, { variant: destructive ? 'destructive' : 'primary', text: ctaText, isLoading: isLoading, disabled: ctaDisabled, onClick: ctaClick }))] })] }) }) }));
}
export function DeprecatedModalSectionList(_a) {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (_jsx("div", { style: Object.assign({ display: 'grid', gap: '24px' }, rest), children: children }));
}
export function DeprecatedModalGrid(_a) {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (_jsx("div", { style: Object.assign(Object.assign({}, (children ? { grid: `1fr / repeat( ${Children.count(children)} , 1fr)` } : {})), rest), className: "u-ui-deprecated-modal-grid", children: children }));
}
export function DeprecatedModalSection(_a) {
    var { children, heading, headingStyles, supportingText } = _a, rest = __rest(_a, ["children", "heading", "headingStyles", "supportingText"]);
    return (_jsxs("div", { className: "u-ui-deprecated-modal-section", children: [(heading || supportingText) && (_jsxs("div", { className: "u-ui-deprecated-modal-section__header", style: headingStyles, children: [heading, supportingText && (_jsx("div", { className: "u-ui-deprecated-modal-section__supporting-text", children: supportingText }))] })), _jsx("div", { style: Object.assign({ display: 'flex', gap: '12px', flexDirection: 'column' }, rest), children: children })] }));
}
