const colors = {
    'grey-25': '#fcfcfd',
    'grey-50': '#f9fafb',
    'grey-100': '#f2f4f7',
    'grey-200': '#e4e7ec',
    'grey-300': '#d0d5dd',
    'grey-400': '#98a2b3',
    'grey-500': '#667085',
    'grey-600': '#475467',
    'grey-700': '#344054',
    'grey-800': '#1d2939',
    'grey-900': '#101828',
    'primary-25': '#F5FCFC',
    'primary-50': '#EDF8F8',
    'primary-100': '#E5F2F2',
    'primary-200': '#C6E6E7',
    'primary-300': '#A5DADC',
    'primary-400': '#82CED2',
    'primary-500': '#58C2C8',
    'primary-600': '#00AEB5',
    'primary-700': '#007D92',
    'primary-800': '#004F68',
    'primary-900': '#00253A',
    'error-25': '#fffbfa',
    'error-50': '#fef3f2',
    'error-100': '#fee4e2',
    'error-200': '#fecdca',
    'error-300': '#fda29b',
    'error-400': '#f97066',
    'error-500': '#f04438',
    'error-600': '#d92d20',
    'error-700': '#b42318',
    'error-800': '#912018',
    'error-900': '#7a271a',
    'warning-25': '#fffcf5',
    'warning-50': '#fffaeb',
    'warning-100': '#fef0c7',
    'warning-200': '#fedf89',
    'warning-300': '#fec84b',
    'warning-400': '#fdb022',
    'warning-500': '#f79009',
    'warning-600': '#b54708',
    'warning-700': '#b42318',
    'warning-800': '#93370d',
    'warning-900': '#7a2e0e',
    'success-25': '#f6fef9',
    'success-50': '#ecfdf3',
    'success-100': '#d1fadf',
    'success-200': '#a6f4c5',
    'success-300': '#6ce9a6',
    'success-400': '#32d583',
    'success-500': '#12b76a',
    'success-600': '#039855',
    'success-700': '#027a48',
    'success-800': '#05603a',
    'success-900': '#054f31',
    'bluegrey-25': '#fcfcfd',
    'bluegrey-50': '#f8f9fc',
    'bluegrey-100': '#eaecf5',
    'bluegrey-200': '#c8cce5',
    'bluegrey-300': '#9ea5d1',
    'bluegrey-400': '#717bbc',
    'bluegrey-500': '#4e5ba6',
    'bluegrey-600': '#3e4784',
    'bluegrey-700': '#363f72',
    'bluegrey-800': '#293056',
    'bluegrey-900': '#101323',
    'bluelight-25': '#f5fbff',
    'bluelight-50': '#f0f9ff',
    'bluelight-100': '#e0f2fe',
    'bluelight-200': '#b9e6fe',
    'bluelight-300': '#7cd4fd',
    'bluelight-400': '#36bffa',
    'bluelight-500': '#0ba5ec',
    'bluelight-600': '#0086c9',
    'bluelight-700': '#026aa2',
    'bluelight-800': '#065986',
    'bluelight-900': '#0b4a6f',
    'blue-25': '#f5fbff',
    'blue-50': '#f0f9ff',
    'blue-100': '#e0f2fe',
    'blue-200': '#b9e6fe',
    'blue-300': '#7cd4fd',
    'blue-400': '#36bffa',
    'blue-500': '#0ba5ec',
    'blue-600': '#0086c9',
    'blue-700': '#026aa2',
    'blue-800': '#065986',
    'blue-900': '#0b4a6f',
    'indigo-25': '#f5f8ff',
    'indigo-50': '#eef4ff',
    'indigo-100': '#e0eaff',
    'indigo-200': '#c7d7fe',
    'indigo-300': '#a4bcfd',
    'indigo-400': '#8098f9',
    'indigo-500': '#6172f3',
    'indigo-600': '#444ce7',
    'indigo-700': '#3538cd',
    'indigo-800': '#2d31a6',
    'indigo-900': '#2d3282',
    'purple-25': '#fafaff',
    'purple-50': '#f4f3ff',
    'purple-100': '#ebe9fe',
    'purple-200': '#d9d6fe',
    'purple-300': '#bdb4fe',
    'purple-400': '#9b8afb',
    'purple-500': '#7a5af8',
    'purple-600': '#6938ef',
    'purple-700': '#5925dc',
    'purple-800': '#4a1fb8',
    'purple-900': '#3e1c96',
    'pink-25': '#fef6fb',
    'pink-50': '#fdf2fa',
    'pink-100': '#fce7f6',
    'pink-200': '#fcceee',
    'pink-300': '#faa7e0',
    'pink-400': '#f670c7',
    'pink-500': '#ee46bc',
    'pink-600': '#dd2590',
    'pink-700': '#c11574',
    'pink-800': '#9e165f',
    'pink-900': '#851651',
    'rose-25': '#fff5f6',
    'rose-50': '#fff1f3',
    'rose-100': '#ffe4e8',
    'rose-200': '#fecdd6',
    'rose-300': '#fea3b4',
    'rose-400': '#fd6f8e',
    'rose-500': '#f63d68',
    'rose-600': '#e31b54',
    'rose-700': '#c01048',
    'rose-800': '#a11043',
    'rose-900': '#89123e',
    'orange-25': '#fffaf5',
    'orange-50': '#fff6ed',
    'orange-100': '#ffead5',
    'orange-200': '#fddcab',
    'orange-300': '#feb273',
    'orange-400': '#fd853a',
    'orange-500': '#fb6514',
    'orange-600': '#ec4a0a',
    'orange-700': '#c4320a',
    'orange-800': '#9c2a10',
    'orange-900': '#7e2410',
    'yellow-25': '#FEFDF0',
    'yellow-50': '#FEFBE8',
    'yellow-100': '#FEF7C3',
    'yellow-200': '#FEEE95',
    'yellow-300': '#FDE272',
    'yellow-400': '#FAC515',
    'yellow-500': '#EAAA08',
    'yellow-600': '#CA8504',
    'yellow-700': '#A15C07',
    'yellow-800': '#854A0E',
    'yellow-900': '#713B12',
    white: '#ffffff',
};
//" tmp grey scale primary colors
// "primary-25":"#fcfcfd",
// "primary-50":"#f9fafb",
// "primary-100": "#f2f4f7",
// "primary-200": "#e4e7ec",
// "primary-300": "#d0d5dd",
// "primary-400": "#98a2b3",
// "primary-500": "#667085",
// "primary-600": "#101828",
// "primary-700": "#344054",
// "primary-800": "#1d2939",
// "primary-900": "#101828",
export const colorCollection = Object.keys(colors);
export default colors;
