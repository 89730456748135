import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Heading, Paragraph } from '../Typography';
import { useLayoutEffect, useRef, useEffect, forwardRef, useImperativeHandle, useState, useContext, Children, } from 'react';
import { IconXClose } from '../Icon';
import { classNames } from '../utils/helperFunctions';
import { useDeviceWidth, useKeyboardShortcut } from '../utils/hooks';
import { Button } from '../Button';
import styles from './Modal.module.scss';
import React from 'react';
export const ModalTransitionContext = React.createContext(undefined);
export const ModalTransition = ({ children }) => {
    const ref = useRef(children);
    const [isTransitioningOut, setIsTransitioningOut] = useState(false);
    const isModalShown = Children.toArray(children).some(Boolean);
    useEffect(() => {
        if (!isModalShown) {
            setIsTransitioningOut(true);
            setTimeout(() => {
                ref.current = children;
                setIsTransitioningOut(false);
            }, 300);
        }
    }, [isModalShown]);
    if (isModalShown) {
        ref.current = children;
    }
    return (_jsx(ModalTransitionContext.Provider, { value: isTransitioningOut, children: ref.current }));
};
export const Modal = forwardRef(({ title, supportingText, children, onClose, size = 'small', footer, map, fullHeight = false, stackedFooter = false, disableScroll = true, }, ref) => {
    const { isMobile } = useDeviceWidth();
    const contentRef = useRef(null);
    const showMapInHeader = size !== 'full' || isMobile;
    const isTransitioningOut = useContext(ModalTransitionContext);
    const modalShouldTransition = isTransitioningOut !== undefined;
    useImperativeHandle(ref, () => contentRef.current);
    useLayoutEffect(() => {
        if (!contentRef.current || fullHeight) {
            return;
        }
        const maybeSetScroll = () => {
            //Since we are modifying the DOM, we need to wait for the next tick
            setTimeout(() => {
                if (!contentRef.current) {
                    return;
                }
                if (contentRef.current.style.overflowY) {
                    contentRef.current.style.removeProperty('overflow-y'); // Resetting to default to get correct measurements
                }
                if (contentRef.current.scrollHeight <= contentRef.current.clientHeight) {
                    contentRef.current.style.overflowY = 'inherit';
                }
            }, 0);
        };
        const observer = new ResizeObserver(maybeSetScroll);
        observer.observe(contentRef.current);
        return () => observer.disconnect();
    }, [fullHeight]);
    useEffect(() => {
        if (disableScroll) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.removeProperty('overflow');
        };
    }, []);
    useEffect(() => {
        var _a;
        const originalPaddingTop = ((_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.style.paddingTop) || '';
        const handleScroll = () => {
            if (contentRef.current) {
                if (contentRef.current.scrollTop === 0) {
                    contentRef.current.style.paddingTop = originalPaddingTop;
                }
                else {
                    contentRef.current.style.paddingTop = '0';
                }
            }
        };
        const current = contentRef.current;
        if (current) {
            current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (current) {
                current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    useKeyboardShortcut('Escape', onClose);
    return (_jsx("div", { id: "GordonModal", className: classNames(styles.modal, styles[`modal--${size}`], fullHeight && styles['modal__full-height'], modalShouldTransition &&
            !isTransitioningOut &&
            styles['modal--transition-background-open'], modalShouldTransition &&
            isTransitioningOut &&
            styles['modal--transition-background-close']), onScroll: (e) => e.stopPropagation(), children: _jsxs("div", { className: classNames(modalShouldTransition && !isTransitioningOut && styles['modal--transition-open'], modalShouldTransition && isTransitioningOut && styles['modal--transition-close']), children: [_jsxs("div", { children: [_jsxs("div", { className: classNames(styles.modal__header), children: [_jsx(Heading, { size: "h3", children: title }), _jsx("div", { className: styles.modal__header__button, children: _jsx(Button, { text: "Close", variant: "tertiary", size: "medium", icon: IconXClose, iconType: "only", onClick: onClose }) }), supportingText &&
                                    (typeof supportingText === 'string' ? (_jsx("div", { className: styles['modal__header__supporting-text'], children: _jsx(Paragraph, { inheritColor: true, children: supportingText }) })) : (_jsx("div", { className: styles['modal__header__supporting-text'], children: supportingText })))] }), children && (_jsxs(_Fragment, { children: [showMapInHeader && map && _jsx("div", { className: styles['modal__header-map'], children: map }), _jsx("div", { className: styles.headerDivider }), _jsx("div", { className: styles.modal__content, ref: contentRef, children: children })] })), children && footer && _jsx("div", { className: styles.footerDivider }), footer && (_jsx("div", { className: classNames(styles.modal__footer, stackedFooter && styles['modal__footer--stacked']), children: footer }))] }), map && !showMapInHeader && _jsx("div", { children: map })] }) }));
});
export function ModalSection({ title, children }) {
    return (_jsxs("div", { className: styles['modal-section'], children: [title && _jsx(Heading, { size: "h4", children: title }), children] }));
}
export function ModalRow({ children, columns }) {
    return (_jsx("div", { className: styles['modal-row'], style: {
            gridTemplateColumns: columns ? `repeat(${columns}, 1fr)` : undefined,
            gridAutoColumns: !columns ? '1fr' : undefined,
            gridAutoFlow: !columns ? 'column' : undefined,
        }, children: children }));
}
export function ModalColumn({ children, span }) {
    return (_jsx("div", { style: {
            gridColumn: span ? `span ${span}` : undefined,
        }, children: children }));
}
export function isModalOpen() {
    return !!document.getElementById('GordonModal');
}
