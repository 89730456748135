import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);
import colors from '../styles/colors';
import { Paragraph, Heading } from '../Typography';
import { localizedNumber } from '../utils/helperFunctions';
import './ProgressDoughnut.scss';
export const ProgressDoughnut = ({ values, totalValue, title, withStats, height, }) => {
    const hasLabels = values.some((obj) => obj.label);
    if (hasLabels) {
        withStats = false;
    }
    const colorValues = values.map((obj) => {
        return colors[obj.color];
    });
    const dataValues = values.map((obj) => {
        return obj.value;
    });
    const labelValues = values.map((obj) => {
        return obj.label;
    });
    const total = totalValue !== undefined
        ? values.reduce((previous, obj) => {
            return previous + obj.value;
        }, 0) - totalValue
        : undefined;
    if (total !== undefined) {
        dataValues.push(total === 0 ? 1 : total);
        colorValues.push(colors['grey-200']);
        labelValues.push('Remaining');
    }
    return (_jsxs("div", { style: { position: 'relative', height: height }, children: [withStats && (_jsxs("div", { className: "doughnut__inner", children: [_jsx(Heading, { size: "h4", children: title }), _jsx(Paragraph, { strong: true, size: "large", children: total !== undefined && totalValue !== undefined
                            ? `${localizedNumber(total + totalValue)} / ${localizedNumber(totalValue)}`
                            : '0 / 0' })] })), _jsx(Doughnut, { options: Object.assign(Object.assign({ maintainAspectRatio: false, responsive: true }, { borderWidth: 0 }), { cutout: '80%', hover: { mode: undefined }, plugins: {
                        tooltip: {
                            enabled: hasLabels,
                        },
                        legend: {
                            display: hasLabels,
                            position: 'bottom',
                        },
                    } }), data: {
                    datasets: [
                        {
                            data: dataValues,
                            backgroundColor: colorValues,
                        },
                    ],
                    labels: labelValues,
                }, role: "meter", "aria-label": title || 'Progress Chart', "aria-valuemin": 0, "aria-valuenow": total != undefined && totalValue != undefined ? total + totalValue : undefined, "aria-valuemax": totalValue })] }));
};
