export const backgroundsColors = {
    interactive: {
        brand: {
            active: 'var(--backgrounds-interactive-brand-active, #00253A)',
            default: 'var(--backgrounds-interactive-brand-default, #037E8C)',
            disabled: 'var(--backgrounds-interactive-brand-disabled, #DDE1E7)',
            hover: 'var(--backgrounds-interactive-brand-hover, #004F68)',
            subtle: 'var(--backgrounds-interactive-brand-subtle, #EDF8F8)',
        },
        default: {
            active: 'var(--backgrounds-interactive-default-active, #F6F8FA)',
            default: 'var(--backgrounds-interactive-default-default, #FFFFFF)',
            disabled: 'var(--backgrounds-interactive-default-disabled, #DDE1E7)',
            hover: 'var(--backgrounds-interactive-default-hover, #F9FAFB)',
        },
        error: {
            active: 'var(--backgrounds-interactive-error-active, #912018)',
            default: 'var(--backgrounds-interactive-error-default, #D92D20)',
            disabled: 'var(--backgrounds-interactive-error-disabled, #DDE1E7)',
            hover: 'var(--backgrounds-interactive-error-hover, #B42318)',
            subtle: 'var(--backgrounds-interactive-error-subtle, #FFECEB)',
        },
        inverse: {
            active: 'var(--backgrounds-interactive-inverse-active, #1D2939)',
            default: 'var(--backgrounds-interactive-inverse-default, #000000)',
            disabled: 'var(--backgrounds-interactive-inverse-disabled, #DDE1E7)',
            hover: 'var(--backgrounds-interactive-inverse-hover, #344054)',
        },
        success: {
            active: 'var(--backgrounds-interactive-success-active, #05603A)',
            default: 'var(--backgrounds-interactive-success-default, #008945)',
            disabled: 'var(--backgrounds-interactive-success-disabled, #DDE1E7)',
            hover: 'var(--backgrounds-interactive-success-hover, #027A48)',
            subtle: 'var(--backgrounds-interactive-success-subtle, #DFFCEA)',
        },
        tonal: {
            active: 'var(--backgrounds-interactive-tonal-active, #DDE1E7)',
            default: 'var(--backgrounds-interactive-tonal-default, #F6F8FA)',
            disabled: 'var(--backgrounds-interactive-tonal-disabled, #DDE1E7)',
            hover: 'var(--backgrounds-interactive-tonal-hover, #EAECF0)',
        },
        warning: {
            active: 'var(--backgrounds-interactive-warning-active, #93370D)',
            default: 'var(--backgrounds-interactive-warning-default, #DC6803)',
            disabled: 'var(--backgrounds-interactive-warning-disabled, #DDE1E7)',
            hover: 'var(--backgrounds-interactive-warning-hover, #B54708)',
            subtle: 'var(--backgrounds-interactive-warning-subtle, #FFF6DB)',
        },
    },
    surface: {
        base: {
            default: 'var(--backgrounds-surface-base-default, #FFFFFF)',
            disabled: 'var(--backgrounds-surface-base-disabled, #DDE1E7)',
            grey: 'var(--backgrounds-surface-base-grey, #EAECF0)',
            'grey-strong': 'var(--backgrounds-surface-base-grey-strong, #667085)',
            'grey-subtle': 'var(--backgrounds-surface-base-grey-subtle, #F6F8FA)',
            inverse: 'var(--backgrounds-surface-base-inverse, #000000)',
            overlay: 'var(--backgrounds-surface-base-overlay, #10182899)',
        },
        brand: {
            blue: 'var(--backgrounds-surface-brand-blue, #01C1F2)',
            'light-blue': 'var(--backgrounds-surface-brand-light-blue, #E7F9FD)',
            'light-moss': 'var(--backgrounds-surface-brand-light-moss, #E8F7F3)',
            purple: 'var(--backgrounds-surface-brand-purple, #6938EF)',
            teal: 'var(--backgrounds-surface-brand-teal, #00B6BE)',
            yellow: 'var(--backgrounds-surface-brand-yellow, #F8CE00)',
        },
    },
};
export const bordersColors = {
    brand: 'var(--borders-brand, #037E8C)',
    'brand-strong': 'var(--borders-brand-strong, #004F68)',
    default: 'var(--borders-default, #DDE1E7)',
    disabled: 'var(--borders-disabled, #98A2B3)',
    error: 'var(--borders-error, #912018)',
    focus: 'var(--borders-focus, #000000)',
    scuccess: 'var(--borders-scuccess, #05603A)',
    selected: 'var(--borders-selected, #000000)',
    strong: 'var(--borders-strong, #344054)',
    subtle: 'var(--borders-subtle, #EAECF0)',
    warning: 'var(--borders-warning, #93370D)',
};
export const iconsColors = {
    'brand-primary': 'var(--icons-brand-primary, #037E8C)',
    'brand-strong': 'var(--icons-brand-strong, #004F68)',
    default: 'var(--icons-default, #344054)',
    disabled: 'var(--icons-disabled, #98A2B3)',
    error: 'var(--icons-error, #D92D20)',
    'error-strong': 'var(--icons-error-strong, #912018)',
    inverse: 'var(--icons-inverse, #FFFFFF)',
    strong: 'var(--icons-strong, #101828)',
    subtle: 'var(--icons-subtle, #667085)',
    success: 'var(--icons-success, #008945)',
    'success-strong': 'var(--icons-success-strong, #05603A)',
    warning: 'var(--icons-warning, #DC6803)',
    'warning-strong': 'var(--icons-warning-strong, #93370D)',
};
export const textColors = {
    'brand-primary': 'var(--text-brand-primary, #037E8C)',
    'brand-strong': 'var(--text-brand-strong, #004F68)',
    default: 'var(--text-default, #344054)',
    disabled: 'var(--text-disabled, #98A2B3)',
    error: 'var(--text-error, #D92D20)',
    'error-strong': 'var(--text-error-strong, #912018)',
    inverse: 'var(--text-inverse, #FFFFFF)',
    link: {
        active: 'var(--text-link-active, #00253A)',
        default: 'var(--text-link-default, #037E8C)',
        hover: 'var(--text-link-hover, #004F68)',
    },
    strong: 'var(--text-strong, #101828)',
    subtle: 'var(--text-subtle, #667085)',
    success: 'var(--text-success, #008945)',
    'success-strong': 'var(--text-success-strong, #05603A)',
    warning: 'var(--text-warning, #DC6803)',
    'warning-strong': 'var(--text-warning-strong, #93370D)',
};
