var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Suspense, lazy } from 'react';
import LoadingSpinnerCentered from '../components/LoadingSpinnerCentered/LoadingSpinnerCentered';
const forceRefreshedKey = '__force-refreshed__';
const lazyWithRetry = (componentImport) => lazy(() => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const pageHasAlreadyBeenForceRefreshed = JSON.parse((_a = window.sessionStorage.getItem(forceRefreshedKey)) !== null && _a !== void 0 ? _a : 'false');
    try {
        const component = yield componentImport();
        window.sessionStorage.setItem(forceRefreshedKey, 'false');
        return component;
    }
    catch (error) {
        if (!pageHasAlreadyBeenForceRefreshed) {
            window.sessionStorage.setItem(forceRefreshedKey, 'true');
            window.location.reload();
            return null;
        }
        throw error;
    }
}));
export const createLazyComponent = (componentImport) => 
// eslint-disable-next-line react/display-name
(props) => {
    const LazyComponent = React.useMemo(() => lazyWithRetry(componentImport), []);
    return (_jsx(Suspense, { fallback: _jsx(LoadingSpinnerCentered, {}), children: _jsx(LazyComponent, Object.assign({}, props)) }));
};
