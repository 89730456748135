export const parseError = (error) => {
    let errorMessage = error.message || 'Something went wrong!';
    if (error.errors && Array.isArray(error.errors) && error.errors.length > 0) {
        const fieldErrors = error.errors
            .map((fieldError) => `${fieldError.key}: ${fieldError.message}`)
            .join('; ');
        errorMessage += ` (${fieldErrors})`;
    }
    return errorMessage;
};
