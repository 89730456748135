import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Heading, Paragraph } from '@gordon/web-ui';
import { PageContent } from '../../components/Page';
import { Section } from '../../components/Section';
import './NotFound.scss';
import { useDeviceWidth } from '@gordon/web-ui';
import { Button } from '@gordon/web-ui';
export const NotFound = ({ error }) => {
    const { t } = useTranslation();
    const { isMobile } = useDeviceWidth();
    const shouldShowAppInfo = error === 'Forbidden' || error === 'Order has expired';
    const handleClick = () => {
        window.location.href = 'https://grdn.io/get-app';
    };
    return (_jsx("div", { className: "not-found", children: _jsxs("div", { className: "not-found__body", children: [_jsx(PageContent, { padding: true, background: true, children: shouldShowAppInfo ? (_jsx("img", { className: "not-found__body__img", src: "/img/app-illustration.svg", alt: "not found page illustration" })) : (_jsx("img", { className: "not-found__body__img", src: "/img/person-relaxing.svg", alt: "not found page illustration" })) }), _jsx("div", { className: "not-found__content", children: _jsx(PageContent, { padding: true, background: true, children: _jsxs(Section, { spacing: "medium", children: [_jsx(Heading, { size: "h5", children: t('notFoundPage.supportingText') }), shouldShowAppInfo ? (_jsxs(_Fragment, { children: [_jsx(Heading, { size: "h1", children: t('notFoundPage.showAppInfo.header') }), _jsx(Paragraph, { size: "large", color: "strong", children: t('notFoundPage.showAppInfo.description') }), isMobile ? (_jsx("div", { className: 'button-navigation', children: _jsx(Button, { size: "large", variant: "primary", text: t('notFoundPage.showAppInfo.buttonText'), onClick: handleClick }) })) : (_jsx("img", { className: "not-found-qr", height: 140, width: 140, src: "/img/getApp.svg", alt: "qr-code to download the app" }))] })) : (_jsxs(_Fragment, { children: [_jsx(Heading, { size: "h1", children: t('notFoundPage.header') }), _jsx(Paragraph, { size: "large", color: "strong", children: t('notFoundPage.description') })] }))] }) }) })] }) }));
};
