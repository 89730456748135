import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useImperativeHandle, useRef } from 'react';
import { forwardRef } from 'react';
import styles from './Input.module.scss';
import { InputLabels } from '../InputLabels/InputLabels';
import { IconXCircle } from '../Icon';
import { classNames } from '../utils/helperFunctions';
export const Input = forwardRef(({ value, defaultValue, autoComplete = 'on', placeholder, icon: Icon, label, hint, error, errorMessage, disabled = false, type = 'text', step, min, max, autoFocus, maxlength = 200, name, onChange = () => { }, onFocus = () => { }, onBlur = () => { }, onKeyDown = () => { }, }, ref) => {
    const internalRef = useRef(null);
    const focusInput = () => {
        if (internalRef.current) {
            internalRef.current.focus();
        }
    };
    useImperativeHandle(ref, () => internalRef.current);
    return (_jsx(InputLabels, { label: label, labelFor: name, hint: hint, errorMessage: disabled ? undefined : errorMessage, children: _jsxs("div", { onClick: focusInput, className: classNames(styles.base, disabled && styles.disabled, (errorMessage || error) && styles.error), children: [Icon && _jsx(Icon, { color: disabled ? 'disabled' : !!value ? 'strong' : 'subtle' }), _jsx("input", { onChange: onChange, onFocus: onFocus, onKeyDown: onKeyDown, onBlur: onBlur, value: value, defaultValue: defaultValue, name: name, id: name, type: type, step: step, min: min, max: max, maxLength: maxlength, autoFocus: autoFocus, autoComplete: autoComplete, placeholder: placeholder, disabled: disabled, ref: internalRef }), _jsx("button", { type: "button", onMouseDown: (e) => {
                        e.preventDefault();
                        if (internalRef.current) {
                            internalRef.current.value = '';
                            onChange({ target: internalRef.current });
                        }
                    }, title: "Clear input", name: "clear", tabIndex: -1, children: _jsx(IconXCircle, { color: disabled ? 'disabled' : 'strong' }) })] }) }));
});
Input.displayName = 'Input';
