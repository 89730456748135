import { useEffect, useRef, useState } from 'react';
import { inProgressStatus } from '../api';
export const useSocket = (trackingId, statusCode, updateOrder) => {
    const websocketRef = useRef();
    const [isAuthorized, setIsAuthorized] = useState(true);
    const [reconnectCount, setReconnectCount] = useState(0);
    const shouldSocketClose = () => {
        return !isAuthorized || !inProgressStatus.includes(statusCode);
    };
    const connect = () => {
        var _a, _b;
        if (process.env.WEBSOCKET_ENDPOINT === undefined ||
            ((_a = websocketRef.current) === null || _a === void 0 ? void 0 : _a.readyState) !== ((_b = websocketRef.current) === null || _b === void 0 ? void 0 : _b.CLOSED) ||
            shouldSocketClose()) {
            return;
        }
        const socket = new WebSocket(`${process.env.WEBSOCKET_ENDPOINT}?trackingId=${trackingId}&user_type=trackingV2`);
        socket.onopen = () => {
            setReconnectCount(0);
        };
        socket.onclose = (event) => {
            if (event.code !== 1000) {
                //Only retry if socket is not closed on purpose
                setReconnectCount((count) => count + 1);
            }
        };
        socket.onmessage = (event) => {
            const eventData = JSON.parse(event.data);
            switch (eventData.type) {
                case 'ping':
                    socket.send(JSON.stringify({ type: 'pong' }));
                    break;
                case 'unauthorized':
                    setIsAuthorized(false);
                    socket.close();
                    break;
                case 'locationUpdate': {
                    const { timeDiff, driverLocation } = eventData.data;
                    if (timeDiff || driverLocation) {
                        updateOrder((order) => order && Object.assign(Object.assign({}, order), { timeDiff: timeDiff !== null && timeDiff !== void 0 ? timeDiff : order.timeDiff, driverLocation: driverLocation !== null && driverLocation !== void 0 ? driverLocation : order.driverLocation }));
                    }
                    break;
                }
                case 'routeUpdate':
                    updateOrder((order) => (Object.assign(Object.assign({}, order), eventData.data)));
                    break;
                default:
                    console.log('Unknown message type');
            }
        };
        websocketRef.current = socket;
    };
    useEffect(() => {
        connect();
        return () => {
            var _a;
            (_a = websocketRef.current) === null || _a === void 0 ? void 0 : _a.close();
        };
    }, [statusCode]);
    useEffect(() => {
        if (reconnectCount > 0) {
            const timeoutId = setTimeout(connect, 5000);
            return () => clearTimeout(timeoutId);
        }
    }, [reconnectCount]);
};
