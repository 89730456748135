var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { classNames } from '../utils/helperFunctions';
import styles from './SelectorGroup.module.scss';
import { Selector } from './Selector';
import { useCallback } from 'react';
export const SelectorGroup = (_a) => {
    var { direction = 'horizontal' } = _a, props = __rest(_a, ["direction"]);
    return (_jsx("div", { className: classNames(styles.base, styles[`direction--${direction}`]), children: props.type === 'checkbox' ? _jsx(CheckboxGroup, Object.assign({}, props)) : _jsx(RadioGroup, Object.assign({}, props)) }));
};
const CheckboxGroup = ({ options, alignment = 'center', size, value, onChange, }) => {
    const handler = useCallback((event) => {
        onChange(value.includes(event.target.value)
            ? value.filter((value) => value !== event.target.value)
            : [...value, event.target.value], event.target.value);
    }, [onChange, value]);
    return (_jsx(_Fragment, { children: options.map((option) => (_jsx(Selector, Object.assign({}, option, { checked: value.includes(option.value), size: size, alignment: alignment, type: "checkbox", onChange: handler }), option.value))) }));
};
const RadioGroup = ({ options, alignment = 'center', size, value, onChange, }) => {
    const handler = useCallback((event) => {
        onChange(event.target.value);
    }, [onChange]);
    return (_jsx(_Fragment, { children: options.map((option) => (_jsx(Selector, Object.assign({}, option, { checked: value === option.value, size: size, alignment: alignment, type: "radio", onChange: handler }), option.value))) }));
};
