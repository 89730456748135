import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
if (process.env.ENVIRONMENT === 'PRODUCTION') {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration()],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
    });
}
const container = document.getElementById('root');
if (!container)
    throw new Error('no root element');
const root = createRoot(container);
root.render(_jsx(BrowserRouter, { children: _jsx(App, {}) }));
