import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ListItem } from '../ListItem/ListItem';
import { Button } from '../Button/Button';
import { IconInfoCircle } from '../Icon';
import { TooltipWithModal } from '../Tooltip';
import { Paragraph } from '../Typography';
import styles from './InfoBlock.module.scss';
export const InfoBlock = ({ heading, body, tooltip, icon, buttonProps }) => {
    const hasIcon = !!icon;
    const Icon = icon || IconInfoCircle;
    return (_jsxs("div", { className: styles['info-block'], children: [tooltip ? (_jsx(TooltipWithModal, { tooltipProps: tooltip, children: _jsx(Icon, { color: "strong" }) })) : (hasIcon && _jsx(Icon, { color: "strong" })), _jsxs("div", { className: styles['content-row'], children: [_jsx(ListItem, { heading: heading, children: _jsx(Paragraph, { children: body }) }), buttonProps && (_jsx("span", { style: { display: 'inline-block' }, children: buttonProps && _jsx(Button, Object.assign({ size: "medium", variant: "secondary" }, buttonProps)) }))] })] }));
};
