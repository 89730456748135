import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { IconHelpCircle } from '../Icon';
import { DeprecatedModal } from '../DeprecatedModal';
import { Paragraph } from '../Typography';
import { useDeviceWidth } from '../utils/hooks';
import { Tooltip } from './Tooltip';
export const TooltipWithModal = ({ tooltipProps, children, }) => {
    const { isTablet, isMobile } = useDeviceWidth();
    const [isModalActive, setIsModalActive] = useState(false);
    const defaultIcon = _jsx(IconHelpCircle, { color: "subtle", size: "small" });
    const { body, title } = tooltipProps;
    return isTablet || isMobile ? (_jsxs(_Fragment, { children: [_jsx("span", { onClick: () => setIsModalActive(true), children: children !== null && children !== void 0 ? children : defaultIcon }), isModalActive && (_jsx(DeprecatedModal, { onClose: () => setIsModalActive(false), hasCta: false, cancelText: "Close", heading: title, children: _jsx(Paragraph, { children: body }) }))] })) : (_jsx(Tooltip, Object.assign({}, tooltipProps)));
};
