import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import './Page.scss';
import { classNames } from '../../utils/helpers';
export const Page = ({ mapView = false, whiteBackground = false, children, }) => {
    useEffect(() => {
        if (mapView) {
            // this will lock the scroll when the map is open on iOS
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.removeProperty('overflow');
        };
    }, []);
    return (_jsx("div", { className: classNames('TrackingPage', mapView && 'TrackingPage--map-view', whiteBackground && 'TrackingPage--white'), children: children }));
};
const PageWrapper = ({ spacing, children, }) => {
    return (_jsx("div", { className: classNames('PageWrapper', spacing === 'small'
            ? 'PageWrapper--spacing-small'
            : spacing === 'medium'
                ? 'PageWrapper--spacing-medium'
                : undefined), children: children }));
};
export const PageHero = ({ children }) => {
    return (_jsx("div", { className: "PageHero", children: _jsx(PageWrapper, { children: children }) }));
};
export const PageBody = ({ children }) => {
    return _jsx("div", { className: "PageBody", children: children });
};
export const PageContent = ({ background, children, spacing, padding = false, }) => {
    return (_jsx("div", { className: classNames('PageContent', background && 'PageContent--background', padding && 'PageContent--padding'), children: _jsx(PageWrapper, { spacing: spacing, children: children }) }));
};
