import React from 'react';
import { Children, isValidElement } from 'react';
// () => str
const simpleId = () => new Date().getTime().toString() + Math.floor(Math.random() * 1000000);
const MOBILE_WIDTH = '767px';
const TABLET_WIDTH = '1023px';
const eventBlur = (e) => {
    e.target.blur();
};
function mergePropsWithChildren(children, props) {
    return React.Children.map(children, (child) => React.isValidElement(child) ? React.cloneElement(child, props) : child);
}
function localizedNumber(number) {
    return number === null || number === void 0 ? void 0 : number.toLocaleString('sv-SE');
}
function classNames(...args) {
    return args.filter((val) => val).join(' ');
}
const stopPropagation = (e) => e.stopPropagation();
const hasChildren = (element) => isValidElement(element) && Boolean(element.props.children);
const childToString = (child) => {
    if (typeof child === 'undefined' || child === null || typeof child === 'boolean') {
        return '';
    }
    if (JSON.stringify(child) === '{}') {
        return '';
    }
    return child.toString();
};
const onlyText = (children) => {
    if (!(children instanceof Array) && !isValidElement(children)) {
        return childToString(children);
    }
    return Children.toArray(children).reduce((text, child) => {
        let newText = '';
        if (isValidElement(child) && hasChildren(child)) {
            newText = onlyText(child.props.children);
        }
        else if (isValidElement(child) && !hasChildren(child)) {
            newText = '';
        }
        else {
            newText = childToString(child);
        }
        return text.concat(newText);
    }, '');
};
export default onlyText;
export { onlyText, simpleId, MOBILE_WIDTH, TABLET_WIDTH, eventBlur, localizedNumber, mergePropsWithChildren, classNames, stopPropagation, };
