var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import headingStyles from './Heading.module.scss';
import paragrahStyles from './Paragraph.module.scss';
import onlyText, { classNames } from '../utils/helperFunctions';
import { textColors } from '../styles/tokens';
export function Heading({ size, // h1, h2, h3, h4, h5, h6
color, disabled = false, inheritColor = false, thin, children, }) {
    const HeadingTag = size;
    return (_jsx(HeadingTag, { className: classNames(headingStyles.base, headingStyles[`size--${size}`], disabled && headingStyles.disabled, inheritColor && paragrahStyles['inherit-color'], thin && headingStyles.thin), style: { color: inheritColor || !color ? undefined : textColors[color] }, children: children }));
}
export function Paragraph(_a) {
    var { size = 'standard', strong = false, color, disabled = false, inheritColor = false, tabular = false, ellipsis = false, italic = false, children } = _a, rest = __rest(_a, ["size", "strong", "color", "disabled", "inheritColor", "tabular", "ellipsis", "italic", "children"]);
    const className = classNames(paragrahStyles.base, paragrahStyles[`size--${size}`], ellipsis && paragrahStyles.ellipsis, strong && paragrahStyles.strong, disabled && paragrahStyles.disabled, inheritColor && paragrahStyles['inherit-color'], tabular && paragrahStyles.tabular, italic && paragrahStyles.italic);
    const title = useMemo(() => (ellipsis ? onlyText(children) : undefined), [ellipsis, children]);
    return (_jsx("p", Object.assign({ title: title, className: className, style: { color: inheritColor || !color ? undefined : textColors[color] } }, rest, { children: children })));
}
