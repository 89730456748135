import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paragraph } from '../Typography';
import styles from './Badge.module.scss';
import { classNames } from '../utils/helperFunctions';
const IconBadgeColors = {
    primary_grey: 'inverse',
    primary_primary: 'inverse',
    primary_error: 'inverse',
    primary_warning: 'strong',
    primary_success: 'inverse',
    primary_disabled: 'disabled',
    primary_brand: 'strong',
    secondary_grey: 'strong',
    secondary_primary: 'brand-strong',
    secondary_error: 'error-strong',
    secondary_warning: 'warning-strong',
    secondary_success: 'success-strong',
    secondary_disabled: 'disabled',
    secondary_brand: 'brand-strong',
};
export function Badge({ size = 'medium', variant = 'grey', text, type = 'secondary', shape = 'pill', icon: Icon, }) {
    return (_jsxs("span", { className: classNames(styles.base, styles[`variant--${variant}-${type}`], Icon && !text ? styles[`size--${size}__icon`] : styles[`size--${size}`], styles[`shape--${shape}`]), children: [Icon && (_jsx(Icon, { color: IconBadgeColors[`${type}_${variant}`], size: size === 'medium' ? 'standard' : size })), text && (_jsx(Paragraph, { size: size === 'medium' ? 'standard' : size, inheritColor: true, strong: true, children: text }))] }));
}
